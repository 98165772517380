import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { isPlatformBrowser } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import { AuthenticatedUser, TokenInfo } from '@tremaze/shared/auth/types';
import { catchError, map } from 'rxjs/operators';
import {
  CredentialsException,
  Exception,
  NotApprovedException,
  RefreshTokenExpiredException,
} from '@tremaze/shared/util-error';
import { User } from '@tremaze/shared/feature/user/types';
import { TremazeHttpResponse } from '@tremaze/shared/util-http/types';

declare const Buffer;

@Injectable({ providedIn: 'root' })
export abstract class AuthV2DataSource {
  abstract loginWithUsernamePassword(
    username: string,
    password: string
  ): Observable<TokenInfo>;

  abstract getRefreshedToken(refreshToken: string): Observable<TokenInfo>;

  abstract getAuthenticatedUser(): Observable<AuthenticatedUser>;
}

@Injectable({ providedIn: 'root' })
export class RemoteAuthV2DataSource implements AuthV2DataSource {
  protected readonly _tokenPath: string;
  protected readonly _httpHeaders: HttpHeaders;

  constructor(
    protected http: HttpClient,
    protected appConfigService: AppConfigService,
    private jsonSerializer: JsonSerializer,
    @Inject(PLATFORM_ID) platformId
  ) {
    this._tokenPath = appConfigService.tokenPath;
    let b64AuthHeader;
    if (isPlatformBrowser(platformId)) {
      b64AuthHeader = `Basic ${btoa(
        `${appConfigService.clientId}:${appConfigService.clientSecret}`
      )}`;
    } else {
      b64AuthHeader = `Basic ${Buffer.from(
        `${appConfigService.clientId}:${appConfigService.clientSecret}`
      ).toString('base64')}`;
    }
    this._httpHeaders = new HttpHeaders({
      Authorization: b64AuthHeader,
      'Content-Type': 'application/x-www-form-urlencoded',
    });
  }

  loginWithUsernamePassword(
    username: string,
    password: string
  ): Observable<TokenInfo> {
    const body = `grant_type=password&username=${username}&password=${password}&client_id=${this.appConfigService?.clientId}`;
    const path = this._tokenPath;
    return this.http
      .post<TokenInfo>(path, body, { headers: this._httpHeaders })
      .pipe(
        map((d) => {

          return TokenInfo.deserialize(d);


        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 400 && err.error.error === 'invalid_grant') {
            if (err.error.error_description == 'User account is locked') {
              return throwError(() => new NotApprovedException());
            }
            return throwError(() => new CredentialsException());
          }
          return throwError(() => err);
        })
      );
  }

  getRefreshedToken(refreshToken: string): Observable<TokenInfo> {
    const body = `grant_type=refresh_token&refresh_token=${refreshToken}`;
    return this.http
      .post<TokenInfo>(this._tokenPath, body, { headers: this._httpHeaders })
      .pipe(
        map((o) => TokenInfo.deserialize(o)),
        catchError((e: HttpErrorResponse) => {
          if (e.status === 401) {
            return throwError(new RefreshTokenExpiredException());
          } else {
            return throwError(new Exception());
          }
        })
      );
  }

  getAuthenticatedUser(): Observable<AuthenticatedUser> {
    return this.http
      .get<AuthenticatedUser>(`cc/user/me`)
      .pipe(map((r) => AuthenticatedUser.deserialize(r)));
  }
}

@Injectable({ providedIn: 'root' })
export class RemoteTremazeCCAuthV2DataSource extends RemoteAuthV2DataSource {
  loginWithUsernamePassword(
    username: string,
    password: string
  ): Observable<TokenInfo> {
    const body = `username=${username}&password=${password}`;
    const path = this._tokenPath + '?grant_type=password';
    return this.http
      .post<TokenInfo>(path, body, { headers: this._httpHeaders })
      .pipe(
        map((d) => TokenInfo.deserialize(d)),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 400 && err.error.error === 'invalid_grant') {
            if (err.error.error_description == 'User account is locked') {
              return throwError(new NotApprovedException());
            }
            return throwError(new CredentialsException());
          }
          return throwError(err);
        })
      );
  }
}

@Injectable({ providedIn: 'root' })
export class RemoteClientAuthV2DataSource extends RemoteAuthV2DataSource {
  getAuthenticatedUser(): Observable<AuthenticatedUser> {
    return this.http
      .get<AuthenticatedUser>(`app/user/me`)
      .pipe(map((r) => AuthenticatedUser.deserialize(r)));
  }
}

@Injectable({ providedIn: 'root' })
export class RemoteForgotPasswordDataSource extends RemoteAuthV2DataSource {
  sendForgotPasswordMail(usernameEmailMobile: string): Observable<User> | null {
    return this.http
      .post<TremazeHttpResponse<any>>(`/public/user/forgotPassword`, null, {
        params: new HttpParams({
          fromObject: { usernameEmailMobile, skipTenantId: 'true' },
        }),
      })
      .pipe(
        map((r) => {
          if (r?.status === 'SUCCESS') {
            return r.object;
          }
          throw r?.message;
        })
      );
  }
}
